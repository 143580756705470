<template>
    <v-container fluid>
        <v-card class="ma-3">

            <v-card-text>
                <v-row>
                    <v-col cols="2">
                        <v-card-title class="text-h4"> {{ selectedShow.data().name }} </v-card-title>
                    </v-col>
                    <v-col cols="10" align-self="center">
                        <v-row no-gutters class="font-weight-bold grey--text text-decoration-underline">
                            <v-col cols="2"> Location </v-col>
                            <v-col cols="3"> Organizer </v-col>
                            <v-col cols="3"> Start Date </v-col>
                            <v-col cols="3"> End Date </v-col>
                            <v-col cols="1"> Edit </v-col>
                        </v-row>
                        <v-row no-gutters class="text-h6 font-weight-bold black--text">
                            <v-col cols="2"> {{ selectedShow.data().location }}</v-col>
                            <v-col cols="3"> {{ selectedShow.data().organizer }}</v-col>
                            <v-col cols="3"> {{ selectedShow.data().startDate }}</v-col>
                            <v-col cols="3"> {{ selectedShow.data().endDate }}</v-col>
                            <v-col cols="1">
                                <v-icon color="black">mdi-pencil</v-icon>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class="mx-3">
            <v-tabs v-model="tab">
                <v-tab> Kiosk Licenses </v-tab>
                <v-tab> User Licenses </v-tab>
                <v-spacer></v-spacer>
                <generate-user-license v-if="tab === 1"></generate-user-license>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-card class="overflow-y-auto" flat elevation="10" :max-height="dataTableDimensions.height">
                        <kiosk-license></kiosk-license>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card class="overflow-y-auto" flat elevation="10" :max-height="dataTableDimensions.height">
                        <user-license></user-license>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';

import GenerateUserLicense from '../components/dialogs/license/generateUserLicense.vue';
import kioskLicense from '../components/dataTables/licenses/kioskLicense.vue';
import userLicense from '../components/dataTables/licenses/userLicense.vue';
export default {
    name: 'showView',
    computed: {
        ...mapGetters(['selectedShow', 'dataTableDimensions']),

    },
    components: {
        GenerateUserLicense,
        kioskLicense,
        userLicense
    },
    data() {
        return {
            tab: null,
        }
    },
}
</script>