<template>
  <v-dialog persistent max-width="30%" v-model="show">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" color="primary" @click="editKiosk">
        mdi-pencil
      </v-icon>
    </template>
    <v-card>
      <v-card-title class="justify-center">Edit License </v-card-title>
      <v-form class="mx-4">
        <v-text-field
          v-model="license.licenseNumber"
          label="License Number"
          disabled
        ></v-text-field>
        <v-text-field v-model="license.name" label="Kiosk Name"></v-text-field>
        <v-text-field
          v-model="license.location"
          label="Kiosk Location"
        ></v-text-field>
        <v-row>
          <v-col cols="6">
            <span> Start Date: {{ license.startDate | formatDate }} </span>
          </v-col>
          <v-col cols="6">
            <span> End Date: {{ license.expiryDate | formatDate }} </span>
          </v-col>
          <v-col cols="6">
            <v-switch v-model="license.enabled" label="Enabled"></v-switch>
          </v-col>
          <v-col cols="6">
            <v-switch
              v-model="available"
              label="Make Available"
              v-if="license.inUse"
            ></v-switch>
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions class="justify-center">
        <v-btn outlined width="100" @click="updateLicense">Save</v-btn>
        <v-btn outlined width="100" @click="show = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";
export default {
  name: "editLicense",
  props: {
    kioskLicense: Object,
  },
  data() {
    return {
      show: false,
      license: {
        licenseNumber: "",
        name: "",
        location: "",
        startDate: "",
        endDate: "",
        inUse: false,
        enabled: false,
      },
      available: false,
    };
  },
  methods: {
    editKiosk() {
      console.log("Kiosk Info: ", this.kioskLicense);
      this.license = Object.assign({}, this.kioskLicense);
      console.log("Kiosk Info assigned : ", this.license);
    },
    updateLicense() {
      console.log("Update License Called");
      this.show = false;
      if (this.available) {
        this.$store.dispatch('updateKioskLicenseAvailable', this.license.licenseNumber)
      } else {
        this.$store.dispatch("updateKioskLicense", this.license);
      }
    },
  },
  filters: {
    formatDate(val) {
      if (!val || val === null) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).format("L");
    },
  },
};
</script>