var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.adminHeadersUsers,"items":_vm.getUserLicense,"items-per-page":_vm.dataTableDimensions.itemsPerPage},scopedSlots:_vm._u([{key:"item.licenseNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().licenseNumber)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().name)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().email)+" ")]}},{key:"item.userStatus",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","small":"","color":_vm.getColor(item.data().userStatus)}},[_vm._v(" "+_vm._s(item.data().userStatus)+" ")])]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [(item.data().status === 'Pending')?_c('span',[_vm._v("Not yet assigned")]):(item.data().status === 'Invited')?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.copyLink(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-copy")]),_vm._v(" Copy Invitation ")],1):_c('span',[_vm._v(" Registered ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.data().status === 'Pending')?_c('assign-user',{attrs:{"userInfo":item}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }