<template>
    <v-data-table :headers="adminHeadersKiosk" :items="getLicenses" :items-per-page="dataTableDimensions.itemsPerPage">
        <template v-slot:item.licenseNumber="{ item }">
            {{ item.data().licenseNumber }}
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on" class="mx-2" small
                        @click="copyLicense(item.data().licenseNumber)">
                        mdi-content-copy
                    </v-icon>
                </template>
                <span>Copy License</span>
            </v-tooltip>
        </template>
        <template v-slot:item.name="{ item }">
            {{ item.data().name }}
        </template>
        <template v-slot:item.location="{ item }">
            {{ item.data().location }}
        </template>
        <template v-slot:item.startDate="{ item }">
            {{ item.data().startDate | formatDate }}
        </template>
        <template v-slot:item.endDate="{ item }">
            {{ item.data().expiryDate | formatDate }}
        </template>
        <template v-slot:item.inUse="{ item }" class="justify-start">
            <!-- <v-switch dense v-model="item.data().inUse" class="ma-0"></v-switch> -->
            <span v-if="item.data().inUse !== 'Assigned' " class="px-2"> AVAILABLE </span>
            <v-btn v-else text class="px-2">
                <span class="primary--text" @click="licenseAvailable(item.data().licenseNumber)"> MAKE AVAILABLE </span>
            </v-btn>
        </template>
        <template v-slot:item.status="{ item }">
            <v-chip dark small :color="getColor(item.data().kioskStatus)">
                {{ item.data().kioskStatus }}
            </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
            <edit-license :kioskLicense="item"></edit-license>
        </template>
    </v-data-table>
</template>
<script>
import { mapGetters } from 'vuex';
import editLicense from '../../dialogs/license/editLicense.vue';
import moment from "moment";
export default {
    name: 'kioskLicense',
    computed: {
        ...mapGetters(['selectedShow', 'kioskLicenses', 'dataTableDimensions']),
        getLicenses() {
            return this.kioskLicenses.filter(license => license.data().showName === this.selectedShow.data().name)
        }
    },
    data() {
        return {
            adminHeadersKiosk: [
                { text: 'License Number', value: 'licenseNumber' },
                { text: 'License Availability', value: 'inUse', sortable: false },
                { text: 'Start Date', value: 'startDate' },
                { text: 'End Date', value: 'endDate' },
                { text: 'Kiosk Name', value: 'name' },
                { text: 'Kiosk Status', value: 'status', sortable: false },
            ],
        }
    },
    components: {
        editLicense
    },
    methods: {
        copyLicense(license) {
            navigator.clipboard.writeText(license);
        },
        getColor(status) {
            if (status === "Unavailable") return "error";
            if (status === "Busy") return "amber";
            if (status === "Available") return "success";
        },
        licenseAvailable(licenseNumber) {
            this.$store.dispatch('licenseAvailable', licenseNumber)
        }
    },
    filters: {
        formatDate(val) {
            if (!val || val === null) {
                return "-";
            }
            let date = val.toDate();
            return moment(date).format("L");
        },
    },
}
</script>