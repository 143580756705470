<template>
  <v-dialog v-model="show" max-width="25%" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" elevation="0" color="primary" class="ma-2">
        <v-icon left> mdi-plus </v-icon>
        Generate User Licensces
      </v-btn>
    </template>
    <v-card>
      <div style="position: absolute; align-self: end; right: 0">
        <v-btn icon @click="show = !show" class="ma-1">
          <v-icon color="red">mdi-window-close</v-icon>
        </v-btn>
      </div>
      <v-card-title class="justify-center"> Generate Licensces </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" align-self="center">
            <v-form>
              <v-text-field v-model="licenses.noOfLicensce" label="Number of Licensce"></v-text-field>
            </v-form>
          </v-col>
          <v-col cols="12">
            <v-btn color="primary" @click="calculateAmount()"> Confirm </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="generateInvoice" class="justify-center">
        <span> Total amount due now: {{ amount }}$</span>
        <v-btn color="primary" class="mx-10" @click="generateLicenses(licenses)">
          Proceed to Pay
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//import TelePricing from "./telePricing.vue";
import { mapGetters } from 'vuex';
export default {
  name: "generateUserLicense",
  data() {
    return {
      show: null,
      loading: false,
      licenses: {
        noOfLicensce: 0,
        showName: '',
        clientName: '',
        startDate: '',
        endDate: ''
      },

      generateInvoice: false,
      amount: 0,
      kiosk: {
        name: "",
        email: "",
        description: "",
        location: "",
        fdn: window.location.hostname,
        subscribe: false,
        telepresence: false,
      },
      rules: {
        nameRules: [(v) => !!v || "Kiosk name is required"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        locationRule: [(v) => !!v || "Location is required"],
      },
    };
  },
  components: {
    //TelePricing,
  },
  computed: {
    ...mapGetters(['userProfile', 'selectedShow'])
  },
  methods: {
    calculateAmount() {
      if (this.noOfLicensce > 0 && this.noOfLicensce <= 10) {
        this.amount = this.noOfLicensce * 30;
      } else if (this.noOfLicensce > 10 && this.noOfLicensce <= 20) {
        this.amount = this.noOfLicensce * 20;
      } else if (this.noOfLicensce > 20) {
        this.amount = this.noOfLicensce * 15;
      }
      this.generateInvoice = true;
    },
    generateLicenses(licenses) {
      this.licenses.clientName = this.selectedShow.data().clientName
      this.licenses.showName = this.selectedShow.data().name
      this.licenses.startDate = this.selectedShow.data().startDate
      this.licenses.endDate = this.selectedShow.data().endDate
      this.show = false;
      this.$store.dispatch("generateUserLicenses", licenses);
    },
  },
  mounted() { },
};
</script>
