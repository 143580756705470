<template>
    <v-dialog persistent v-model="show" width="30%">
        <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" medium class="mr-2" color="primary" @click="getUser()">
                mdi-account-plus
            </v-icon>
        </template>
        <v-card>
            <v-card-title class="justify-center"> Assign User </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-text-field label="Full Name" v-model="userData.name"></v-text-field>
                <v-text-field label="User Email" v-model="userData.email"></v-text-field>
                <v-text-field label="User Role" v-model="userData.userRole" disabled></v-text-field>
                <v-text-field label="License Number" v-model="userData.licenseNumber" disabled></v-text-field>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn @click="show = !show" width="125" outlined color="error"> Close </v-btn>
                <v-btn @click="inviteUser()" width="125" color="primary"> Invite </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'assignUser',
    data() {
        return {
            show: false,
            userData: {
                name: '',
                email: '',
                userRole: 'General',
                licenseNumber: ''
            }
        }
    },
    props: {
        userInfo: Object
    },
    computed: {
        ...mapGetters(['selectedShow'])
    },
    methods: {
        getUser() {
            console.log('User License Info: ', this.userInfo.data())
            this.userData.licenseNumber = this.userInfo.data().licenseNumber
        },
        inviteUser() {

            let data = {
                name: this.userData.name,
                email: this.userData.email,
                userRole: this.userData.userRole,
                clientName: this.selectedShow.data().clientName,
                fdn: window.location.hostname,
                licenseNumber: this.userData.licenseNumber,
                showName: this.selectedShow.data().name
            }

            this.$store.dispatch('updateUserLicenseWithInfo', data).then(() => {
                this.$store.dispatch('inviteNewUser', data).then(() => {
                    this.show = false
                })
            })

        }
    }
}
</script>