<template>
    <v-data-table :headers="adminHeadersUsers" :items="getUserLicense"
        :items-per-page="dataTableDimensions.itemsPerPage">
        <template v-slot:item.licenseNumber="{ item }">
            {{ item.data().licenseNumber }}
        </template>
        <template v-slot:item.name="{ item }">
            {{ item.data().name }}
        </template>
        <template v-slot:item.email="{ item }">
            {{ item.data().email }}
        </template>
        <template v-slot:item.userStatus="{ item }">
            <v-chip dark small :color="getColor(item.data().userStatus)">
                {{ item.data().userStatus }}
            </v-chip>
        </template>
        <template v-slot:item.link="{ item }">
            <span v-if="item.data().status === 'Pending'">Not yet assigned</span>
            <v-btn v-else-if="item.data().status === 'Invited'" text color="primary" @click="copyLink(item)">
                <v-icon left>mdi-content-copy</v-icon>
                Copy Invitation
            </v-btn>
            <span v-else> Registered </span>
        </template>

        <template v-slot:item.actions="{ item }">
            <assign-user :userInfo="item" v-if="item.data().status === 'Pending'"></assign-user>
        </template>
    </v-data-table>
</template>
<script>
import { mapGetters } from 'vuex';
import assignUser from '../../dialogs/user/assignUser.vue';
import editLicense from '../../dialogs/license/editLicense.vue';
import moment from "moment";
export default {
    name: 'userLicense',
    computed: {
        ...mapGetters(['selectedShow', 'userLicenses', 'dataTableDimensions']),
        getUserLicense() {
            return this.userLicenses.filter(license => license.data().showName === this.selectedShow.data().name)
        }
    },
    components: {
        assignUser,
        editLicense
    },
    data() {
        return {
            adminHeadersUsers: [
                { text: 'License Number', value: 'licenseNumber' },
                { text: 'User Name', value: 'name' },
                { text: 'User Email', value: 'email' },
                { text: 'Status', value: 'userStatus' },
                { text: 'Invitation Link', value: 'link', sortable: false },
                { text: 'Actions', value: 'actions' }
            ],
            registrationLink: window.location.hostname + "/registration/",
            userId: ''
        }
    },
    methods: {
        copyLink(user) {
            // get the user from license number then procee
            this.$store.dispatch('fetchInvitedUserFromLicense', user.data()).then(response => {
                //console.log('Response: ', response)
                response.forEach(user => {
                    this.userId = user.id
                    console.log(user, user.data())
                    navigator.clipboard.writeText(window.location.hostname + "/registration/" + user.id)
                })
            })
        },
        getColor(status) {
            console.log('Kiosk Status: ', status)
            if (status === "Unavailable") return "error";
            if (status === "Busy") return "amber";
            if (status === "Available") return "success";
        },
    },
    filters: {
        formatDate(val) {
            if (!val || val === null) {
                return "-";
            }
            let date = val.toDate();
            return moment(date).format("L");
        },
    },
}
</script>