var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.adminHeadersKiosk,"items":_vm.getLicenses,"items-per-page":_vm.dataTableDimensions.itemsPerPage},scopedSlots:_vm._u([{key:"item.licenseNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().licenseNumber)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"primary","dark":"","small":""},on:{"click":function($event){_vm.copyLicense(item.data().licenseNumber)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-copy ")])]}}],null,true)},[_c('span',[_vm._v("Copy License")])])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().name)+" ")]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().location)+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data().startDate))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data().expiryDate))+" ")]}},{key:"item.inUse",fn:function(ref){
var item = ref.item;
return [(item.data().inUse !== 'Assigned' )?_c('span',{staticClass:"px-2"},[_vm._v(" AVAILABLE ")]):_c('v-btn',{staticClass:"px-2",attrs:{"text":""}},[_c('span',{staticClass:"primary--text",on:{"click":function($event){_vm.licenseAvailable(item.data().licenseNumber)}}},[_vm._v(" MAKE AVAILABLE ")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","small":"","color":_vm.getColor(item.data().kioskStatus)}},[_vm._v(" "+_vm._s(item.data().kioskStatus)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('edit-license',{attrs:{"kioskLicense":item}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }